import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import WavesImage from "../components/waves-image"
import TerenceImg from "../assets/terence.svg"
import AndreImg from "../assets/andre.svg"
import MoImg from "../assets/mo.svg"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from "prismic-reactjs"

const imgs = { andre: AndreImg, terence: TerenceImg, mo: MoImg }
export const query = graphql`
  query AboutQuery($lang: String) {
    prismicAbout(lang: { eq: $lang }) {
      data {
        founders {
          name
          position
        }
        meta_description
        meta_keywords
        text_main {
          text
          raw
        }
        title_founders
        title_main
        title_meta
        title_values
        values {
          text
          title
        }
      }
    }
  }
`

const RenderBody = ({ founders, main, values }) => {
  return (
    <Fragment>
      <section className="bg-dark py-6">
        <div className="container">
          <h1 className="text-white text-center">{main.title}</h1>
        </div>
      </section>
      <WavesImage page="home" />
      <section className="py-7 border-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">{RichText.render(main.text.raw)}</div>
          </div>
        </div>
      </section>
      <section className="py-7 text-center border-bottom">
        <div className="container">
          <h2 className="mt-7 section-title">{founders.title}</h2>
          <div className="row">
            {founders.items.map((item, i) => (
              <div key={i} className="col-md-4 py-3">
                <figure className="figure">
                  <img
                    className="figure-img img-fluid"
                    src={imgs[item.name.split(" ")[0].toLowerCase()]}
                    width="200"
                    alt="founder"
                  />
                  <figcaption className="figure-caption">
                    <div className="font-weight-bold">{item.name}</div>
                    <small className="text-muted">{item.position}</small>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-7 text-center">
        <div className="container">
          <h2 className="mt-7 section-title">{values.title}</h2>
          <div className="row justify-content-center">
            {values.items.map((item, i) => (
              <div key={i} className="col-8 col-md-4 py-4">
                <h4 className="text-primary">{item.title}</h4>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  )
}

const AboutTemplate = ({ data, pageContext, location }) => {
  const { lang: locale } = pageContext

  const doc = data.prismicAbout.data
  if (!doc) return null
  const {
    founders,
    text_main,
    title_founders,
    title_main,
    title_values,
    values,
    title_meta,
    meta_keywords,
    meta_description,
    image_meta,
  } = doc

  return (
    <Layout
      metadata={{
        title: title_meta,
        description: meta_description,
        keywords: meta_keywords,
        image: image_meta && image_meta.url,
      }}
      mainPage="companies"
      locale={locale}
      path={location && location.pathname}
    >
      <RenderBody
        founders={{ items: founders, title: title_founders }}
        main={{ title: title_main, text: text_main }}
        values={{ title: title_values, items: values }}
        locale={locale}
      />
    </Layout>
  )
}

export default withPreview(AboutTemplate)
